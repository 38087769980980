.container {
  width: 80vw;
  padding: 30px 0px;
}

.social-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}

.title-page {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #222;
}

.subtitulo {
  font-size: 16px;
  color: #424242;
}

.link {
  word-break: break-all;
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid #000080;
  padding: 10px 20px;
  border-radius: 8px;

  box-shadow: 3px 3px 8px #22222234;
}

.link:nth-child(1) img {
  width: 28px;
}

.link:nth-child(2) img {
  width: 32px;
}

.link:nth-child(3) img {
  width: 20px;
}

.text-social {
  margin-bottom: 2px;
  font-size: 20px;
  color: #222;
}

.link:hover .text-social {
  color: #000080;
}

.link:hover {
  box-shadow: 3px 3px 8px #2222227a;
}