/*Sacop Listagem de informações e arquivos*/
.containerInfor {
    max-width: 1201px;
    margin: 0 auto;
    padding: 50px 0;
    font-size: 0.9rem;
    /* display: grid; */
    /* grid-template-columns: 200px 200px 200px; */
  }
  .rowInfor {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    padding: 10px 0;
    text-transform: uppercase;
  }
  .rowInforItem1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    padding: 14px 0;
    text-transform: uppercase;
    text-align: justify;
  }
  @media screen and (max-width: 1300px) {
    .containerInfor {
      padding: 50px 22px;
    }
  }
  @media screen and (max-width: 740px) {
    .rowInfor {
      grid-template-columns: 1fr;
      grid-row-gap: 25px;
      padding: 10px 0;
      font-size: 0.9em;
    }
    .rowInforItem1 {
      font-size: 0.9em;
    }
  }
  /* AVISOS HOME */
  
  .container {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    max-width: 100vw;
  }
  
  .container h2 {
    text-align: center;
    color: #222;
  }
  
  .box {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 1.5rem;
    padding-top: 0.25rem;
  }
  
  .container_avisos_home {
    padding: 20px;
    width: 100%;
    display: flex;
    gap: 1rem;
    animation: carousel 20s linear infinite;
    transition: 0.2s ease-out;
  }
  
  .card_aviso {
    position: relative;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 280px;
    width: 480px;
    height: 220px;
    flex: 0 0 auto;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card_aviso a {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .card_aviso:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card_aviso h4 {
    font-size: 16px;
    text-align: justify;
    text-decoration: underline;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .card_aviso ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .card_aviso li {
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
  }
  
  .card_aviso b {
    color: #000;
    font-weight: 600;
  }
  
  .icon {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 10px;
    right: 20px;
  }
  
  @keyframes carousel {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @media screen and (max-width: 500px) {
    .card_aviso {
      max-width: 300px;
      height: 230px;
    }
    .icon {
      width: 35px;
      height: 35px;
    }
  }